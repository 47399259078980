
import { useState, useRef } from 'react';
import axios from 'axios';
import aboutbanner from '../assets/aboutbanner.jpg';
import emailjs from 'emailjs-com';
import PosterOne from '../share/component.jsx/posterone'
import carouselimg2 from '../assets/carouselimg2.jpg';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Contact = () => {
    return (
        <div>
            <PosterOne img={aboutbanner} title={'Contact'} />
            <ContactForm />
        </div>
    )
}

export default Contact;


export const ContactForm = () => {
    const [phone, setPhone] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        consultation_type: 'CLINICAL CONSULTATION',
        message: '',
        selectedFile: null,
    });
    const [errors, setErrors] = useState({});
    const maxSize = 25 * 1024 * 1024; // 25MB in bytes

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= maxSize) {
            setFormData({
                ...formData,
                selectedFile: file,
            });
        } else {
            setErrors((prev) => ({
                ...prev,
                selectedFile: 'File size exceeds the 25MB limit.',
            }));
        }
    };

    const handlePhoneChange = (value) => {
        setPhone(value);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'The name field is required.';
        if (!formData.email) newErrors.email = 'The email field is required.';
        if (!phone) newErrors.phone = 'The phone field is required.';
        if (!formData.message) newErrors.message = 'The message field is required.';
        if (!formData.selectedFile) newErrors.selectedFile = 'The image field is required.';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const sendContactForm = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phone', phone);
        formDataToSend.append('consultation_type', formData.consultation_type);
        formDataToSend.append('message', formData.message);
        formDataToSend.append('image', formData.selectedFile);

        try {
            const response = await axios.post('https://projects.drsiamalmobarakcardio.com/api/contact', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                alert('Message sent successfully!');
                setFormData({
                    name: '',
                    email: '',
                    consultation_type: 'CLINICAL CONSULTATION',
                    message: '',
                    selectedFile: null,
                });
                setPhone('');
                setErrors({});
            } else {
                setErrors(response.data.response);
            }
        } catch (error) {
            console.error('Failed to send form:', error);
            alert('Failed to send the message. Please try again.');
        }
    };

    return (
        <div className='container'>
            <div className='text-center' style={{ paddingTop: "20px" }}>
                <i>REQUEST FOR CONSULTATION</i>
                <h3>GET IN TOUCH WITH US</h3>
            </div>
            <div className='contact-form'>
                <form onSubmit={sendContactForm}>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-6 mt-4">
                                    <input
                                        type="text"
                                        placeholder='Your Name*'
                                        name='name'
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        
                                    />
                                    {errors.name && <p className="error-text">{errors.name}</p>}
                                </div>
                                <div className="col-md-6 mt-4">
                                    <input
                                        type="email"
                                        placeholder='Your Email*'
                                        name='email'
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        
                                    />
                                    {errors.email && <p className="error-text">{errors.email}</p>}
                                </div>
                                <div className="col-md-6 mt-4">
                                    <PhoneInput
                                        placeholder="Enter phone number*"
                                        value={phone}
                                        onChange={handlePhoneChange}
                                        
                                    />
                                    {errors.phone && <p className="error-text">{errors.phone}</p>}
                                </div>
                                <div className="col-md-6 mt-4">
                                    <select
                                        className='form-select'
                                        name="consultation_type"
                                        value={formData.consultation_type}
                                        onChange={handleInputChange}
                                        
                                    >
                                        <option value="CLINICAL CONSULTATION">CLINICAL CONSULTATION</option>
                                        <option value="INTERVENTIONAL CARDIOLOGY">INTERVENTIONAL CARDIOLOGY</option>
                                        <option value="CLINICAL RESEARCH">CLINICAL RESEARCH</option>
                                        <option value="MEDICAL ACADEMIC CONSULTATION">MEDICAL ACADEMIC CONSULTATION</option>
                                        <option value="CLINICAL ULTRASOUND TRAINING">CLINICAL ULTRASOUND TRAINING</option>
                                        <option value="DENTAL CONSULTATION">DENTAL CONSULTATION</option>
                                        <option value="DIET & NUTRITIONS">DIET & NUTRITIONS</option>
                                        <option value="OTHERS">OTHERS</option>
                                    </select>
                                </div>
                                <div className="col-12 mt-4">
                                    <textarea
                                        placeholder='Your Message...'
                                        name='message'
                                        value={formData.message}
                                        onChange={handleInputChange}
                                        
                                    />
                                    {errors.message && <p className="error-text">{errors.message}</p>}
                                </div>
                                <input
                                    type="file"
                                    accept=".pdf,.doc,.docx,.txt,.png,.jpg,jpeg"
                                    onChange={handleFileChange}
                                    style={{ marginLeft: "11px", width: "97.5%" }}
                                />
                                    <p>Drag and drop a file here or click to select a file. Maximum of 5 files with a maximum size of 25 MBs; supported formats are (DOC, DOCX, GIF, PDF, PNG, JPG, JPEG, TXT, XLS, and XLSX.)</p>

                                {errors.selectedFile && <p className="error-text">{errors.selectedFile}</p>}
                            </div>
                            <button type='submit' className='btn-two w-100 mt-4'>Send Message</button>
                        </div>
                        <div className="col-md-4" style={{ marginTop: '22px' }}>
                            <div className='mt-4 p-4' style={{ border: "1px solid #dcdcdc" }}>
                                <i>Guangxi, China.</i>
                                <ul className='contact-address'>
                                    <li><LocationOnIcon /> Address: House 201, Ronghua Apartment, Kunbin Lane, Santang Town, Xingning District, Nanning, Guangxi, China.</li>
                                    <li><PhoneIcon /> Phone: <a href="tel:+86 19811976744" style={{ color: '#dc3545' }}> (+86) 198 119 76744</a></li>
                                    <li><MailIcon /> Email: <a href="mailto:consult@drsiamalmobarakcardio.com" style={{ color: '#dc3545' }}> consult@drsiamalmobarakcardio.com</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

