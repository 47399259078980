import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import PosterOne from '../share/component.jsx/posterone'
import blogbanner from '../assets/aboutbanner.jpg'
import ShareIcon from '@mui/icons-material/Share';
import blog1 from '../assets/blog1.jpg';
import SearchIcon from '@mui/icons-material/Search';
import { News } from '.';
import { API_BASE_URL, API_ENDPOINTS, getblogelement } from '../../Services/constant';
import axios from 'axios';
import { ClassSharp } from '@mui/icons-material';



const BlogDetails = () => {
  const { id } = useParams(); // Extract the id parameter from the URL
  const [blog, setBlog] = useState("");


  const [data, setData] = useState([]);
 console.log("restp=>", blog)
  useEffect(() => {
    axios.get(`https://projects.drsiamalmobarakcardio.com/api/blogs/detail/${id}`)
      .then((response) => {
        setBlog(response.data);
            //   console.log('Blog details:', response.data);
      })
      .catch((error) => {
        console.error('Error fetching blog details:', error);
      });
  }, [id]);


  return (
    <>
      <PosterOne img={blogbanner} title={'Blogs Details'} />
      <div>
    
      {blog ? ( // Check if blog data exists
          <>
              <div className="container py-5">
        <div className="row">
          
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="blog-post">
              
                    <div class="single-blog-item">
                        <div class="img-holder">
                            <img src={blog?.data?.image} width="500" alt="Awesome Image"/>
                           
                        </div>
                        <div class="text-holder">
                            
                            <h3 class="blog-title"> {blog?.data?.title}</h3>
                            <div class="text">
                                <p> {blog?.data?.sub_title}</p>
                            </div>
                        </div>    
                    </div>
                  
                    <div class="middle-content-box">
                        <div class="row">
                            <div class="col-md-12">
                               
                                <div class="text-holder">
                                 
                                    <h3> {blog?.data?.heading}</h3>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                  
                    <div class="bottom-content-box">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="text-holder">
                                    {/* <p>{blog?.data?.description}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: blog?.data?.description }}></p>

                                </div>

                            </div>
                        </div>
                    </div>
                   
                    
                   
                </div>
            </div>
        
      
        </div>
      </div>
          </>
        ) : (
          <p>Loading...</p> // Render loading message if blog data is not available
        )}

    </div>
  
    </>
  )
}

export default BlogDetails

