import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail'; // Make sure to import your down arrow icon

import SearchIcon from '@mui/icons-material/Search';
import UpArrowIcon from '@mui/icons-material/ArrowUpward';
import DownArrowIcon from '@mui/icons-material/ArrowDownward';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import logo from '../assets/logo.png';
import AddIcon from '@mui/icons-material/Add';
import { admin, clinical, healthcare, product, research, researchcard } from '../../Navigation/constant';
<li className="nav-item">
    <NavLink to="services" className="nav-link" >Services</NavLink>
    <div className='nav-sub-item .fadeInUp'>
        <ul>
            <li><NavLink to={research}>Research</NavLink></li>
            <li><NavLink to={clinical}>Clinical Knowledge</NavLink></li>
            <li><NavLink to={product}>Medical Product</NavLink></li>
        </ul>
    </div>
</li>
const Header = () => {
    const [scrolled, setScrolled] = useState(false);
    const [searchAlert, setSearchAlert] = useState(false);
    const [targetText, setTargetText] = useState('');
    const [navState, setNavstate] = useState('publications');
    const [searchResults, setSearchResults] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(-1);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 100) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const clearHighlights = () => {
        const highlightedElements = document.querySelectorAll('.highlight');
        highlightedElements.forEach(element => {
            element.outerHTML = element.innerHTML; // Remove the span, keeping the inner content
        });
    };

    const scrollToText = (e) => {
        e.preventDefault();
        clearHighlights(); // Clear previous highlights
        setSearchResults([]); // Clear previous search results
        setCurrentIndex(-1); // Reset current index

        if (targetText.length !== 0) {
            const targetTextLowercase = targetText.toLowerCase();
            const elementsToSearch = document.querySelectorAll('p, h1, h2, h3, h4, h5, h6, button, a, li');
            const results = [];

            const findTargetText = (element, targetTextLowercase) => {
                const textNodes = [];

                // Function to recursively get all text nodes
                const getTextNodes = (node) => {
                    if (node.nodeType === Node.TEXT_NODE) {
                        textNodes.push(node);
                    } else {
                        node.childNodes.forEach(getTextNodes);
                    }
                };

                getTextNodes(element);

                for (const textNode of textNodes) {
                    const elementTextLowercase = textNode.textContent.toLowerCase();
                    if (elementTextLowercase.includes(targetTextLowercase)) {
                        const parent = textNode.parentNode;
                        const regex = new RegExp(`(${targetText})`, 'gi');
                        parent.innerHTML = parent.innerHTML.replace(regex, '<span class="highlight">$1</span>');
                        results.push(parent);
                    }
                }
            };

            elementsToSearch.forEach(element => {
                findTargetText(element, targetTextLowercase);
            });

            if (results.length > 0) {
                setSearchResults(results);
                setCurrentIndex(0);
                results[0].scrollIntoView({ behavior: 'smooth', block: 'center' }); // Scroll to the first match
            }
        }
    };

    const navigateResults = (direction) => {
        if (searchResults.length === 0) return;

        let newIndex = currentIndex + direction;
        if (newIndex < 0) newIndex = searchResults.length - 1;
        if (newIndex >= searchResults.length) newIndex = 0;

        setCurrentIndex(newIndex);
        searchResults[newIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
    };



    return (
        < >

            <header >
                <div className="container cmd">
                    <ul className='iconList'>
                  

                        <li ><PhoneIcon className='ms-0' />Phone:<Link to={"tel:+86 198 1197 6744"} style={{ color: '#8e8a8a' }}> (+86 )198 1197-6744 </Link></li>
                        <li><MailIcon />Email: <Link to={"mailto:consult@drsiamalmobarakcardio.com "} style={{ color: '#8e8a8a' }}>consult@drsiamalmobarakcardio.com</Link></li>
                        <li className='ms-5'>  <select id="languageSelect" class="dropdown- ">
                                    <option value="" disabled selected>Select a language</option>
                                </select></li>
                   </ul>

                </div>
            </header>
            <nav className={`navbar navbar-expand-lg navbar-light   ${scrolled ? 'scrolled  sticky-top fadeInUp' : ''} `}>
                <div className="container">
                    {/* <a className="navbar-brand" >DR. SIAM AL <span style={{ color: "#2ACB35" }}>MOBARAK</span></a> */}
                    <NavLink className="navbar-brand" ><img src={logo} alt="" /></NavLink>
                    <SwipeableTemporaryDrawer />
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item ">
                                <NavLink to='/' className="nav-link" >Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="about" className="nav-link" >About</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="gallery" className="nav-link" >Gallery</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="videos" className="nav-link" >Videos</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink to="research" className="nav-link" >Research</NavLink>
                            </li> */}
                            {/* <li className="nav-item">
                                <NavLink to="clinical-knowledge" className="nav-link" >Clinical knowledge</NavLink>
                            </li> */}
                        

                            <li className="nav-item">
                                <NavLink to={navState} className="nav-link" >Learning Zone</NavLink>
                                <div className='nav-sub-item .fadeInUp'>
                                    <ul>
                                        <li onClick={() => setNavstate(research)}><NavLink to={research}>Publications</NavLink></li>
                                        <li onClick={() => setNavstate(researchcard)}><NavLink to={researchcard}>Research</NavLink></li>
                                        <li onClick={() => setNavstate(clinical)}><NavLink to={clinical}>Easy Clinical Learning</NavLink></li>
                                        <li onClick={() => setNavstate(clinical)}><NavLink to={healthcare}>HealthCare</NavLink></li>
                                        <li onClick={() => setNavstate(product)}><NavLink to={product}>OTHERs</NavLink></li>
                                    </ul>
                                </div>
                            </li>

                            <li className="nav-item">
                                <NavLink to="consultations" className="nav-link" >CONSULTATIONS </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="blogs" className="nav-link" >Blogs</NavLink>
                            </li>
                            
                            <li className="nav-item Navsearch" style={{ borderLeft: "1px solid #EFEDED" }}>
                            <NavLink to="#" className="nav-link" onClick={() => setSearchAlert(!searchAlert)} ><SearchIcon /></NavLink>
                            {searchAlert ? (
                                <div className={`nav-searchBox ${searchAlert ? 'fadeInUp' : ""}`}>
                                    <form onSubmit={scrollToText}>
                                        <div className='d-flex align-items-center' style={{ height: "36px" }}>
                                            <input type="search" placeholder='Search here' onChange={(e) => setTargetText(e.target.value)} />
                                            <div className='nav-ic-box' onClick={scrollToText}>
                                                <SearchIcon />
                                            </div>
                                        </div>
                                    </form>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            {searchResults.length > 0 && (
                                                <div>
                                                    {currentIndex + 1} / {searchResults.length}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <button onClick={() => navigateResults(-1)}><UpArrowIcon /></button>
                                            <button onClick={() => navigateResults(1)}><DownArrowIcon /></button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </li>


                        </ul>
                        <NavLink to='contact'>  <form className="form-inline my-2 my-lg-0">
                            {/* <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" /> */}
                            <button className="btn-two ms-3" type="submit">APPOINTMENT</button>
                        </form>
                        </NavLink>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header




export const SwipeableTemporaryDrawer = () => {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const listData = [
        {
            name: 'Home',
            path: '/'
        },
        {
            name: 'About',
            path: '/about'
        },
        {
            name: 'Gallery',
            path: '/gallery'
        },

        {
            name: 'Videos',
            path: '/videos'
        },
        {
            name: 'Publications',
            path: '/publications'
        },
        {
            name: 'Research',
            path: '/research'
        },
        {
            name: 'Easy Clinical Learning',
            path: '/easy-clinical-learning'
        },
        {
            name: 'Health Care ',
            path: '/healthcare'
        },
        {
            name: 'Others',
            path: '/others'
        },
        {
            name: 'Consultations',
            path: '/consultations'
        },
        {
            name: 'Blogs',
            path: '/blogs'
        },
        {
            name: 'Appointment',
            path: '/contact'
        },

    ]

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {listData.map((text, index) => (
                    <ListItem key={text.name} disablePadding>
                        <NavLink to={text.path} >
                            <ListItemButton>
                                {/* <ListItemIcon>
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon> */}
                                <ListItemText primary={text.name} />
                            </ListItemButton>
                        </NavLink>
                    </ListItem>
                ))}
            </List>
            {/* <Divider /> */}

        </Box>
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <button onClick={toggleDrawer(anchor, true)} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))
            }
        </div >
    );
}