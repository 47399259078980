import React from 'react'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close'; // Importing the CloseIcon

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Link } from 'react-router-dom';
import { admin } from '../../Navigation/constant';
import footer from '../assets/footer-logo.png';
import { NewsletterSubscription } from './NewsLetterEmail';




const Footer = () => {
    return (
        <>
            <div className='footerTop'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div>
                                <i><span><img src={footer} alt=""  style={{   width: '256px' }}/></span></i>
                                {/* <img src={footer} alt="" /> */}
                                <p style={{ fontSize: "14px", marginTop: "0.5rem" }}>
                                I am a cardiology clinical physician with Cath-Lab experience in Interventional Cardiology, have published research papers, and am familiar with EUMDR, FDA, ISO, and NPMA regulations.
                                    {/* Our commitment to your heart health is unwavering, and we invite you to be a part of our story as we continue to make strides in the world of cardiovascular medicine. */}
                                </p>
                            </div>
                            <div>
                                <ul className='footer-address'>
                                    <li><LocationOnIcon />Address: House 201, Ronghua Apartment, kunbin lane,  <br /> Santang Town, Xingning District, Nanning, Guangxi, China. </li>
                                   <li><PhoneIcon />Phone: <Link to={"tel:+86  198 119 76744"} style={{ color: '#8e8a8a' }} > (+86 ) 198 1197 6744 </Link></li>
                                    <li><MailIcon /> Email:<Link to={"mailto:consult@drsiamalmobarakcardio.com "} style={{ color: '#8e8a8a' }}> consult@drsiamalmobarakcardio.com </Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 col-xs-12'>
                            <div style={{ color: "#fff", marginLeft: "1rem" }}>
                                <h5>USEFULL LINKS</h5>
                                <div className='d-flex'>
                                    <ul className='footer-links' style={{ borderRight: "1px solid #2e2e2e" }}>
                                    <Link to={"/About"} style={{ color: '#8e8a8a' }}>  <li>   About Us</li> </Link>
                                    <Link to={"/Gallery"} style={{ color: '#8e8a8a' }} >   <li> Gallery </li></Link>
                                    <Link to={"/Videos"} style={{ color: '#8e8a8a' }}>  <li>  Videos</li></Link>
                                    <Link to={"/blogs"} style={{ color: '#8e8a8a' }}>   <li>  Blogs</li></Link>
                                    <Link to={"/contact"} style={{ color: '#8e8a8a' }}>  <li> Appointment </li></Link>
                                    {/* <Link to={"/Services"} style={{ color: '#8e8a8a' }}>  <li> Services</li></Link> */}
                                    </ul>
                                    <ul className='footer-links' style={{ marginLeft: '1.5rem', }}>
                                       
                                    <Link to={"/publications"} style={{ color: '#8e8a8a' }}>   <li>  Publications </li></Link>
                                    <Link to={"/research"} style={{ color: '#8e8a8a' }}>   <li> Research </li></Link>
                                    <Link to={"/easy-clinical-learning"} style={{ color: '#8e8a8a' }}>   <li>  Easy Clinical Learning </li></Link>
                                    <Link to={"/healthcare"} style={{ color: '#8e8a8a' }}>   <li>  Healthcare </li></Link>
                                    <Link to={"/consultations"} style={{ color: '#8e8a8a' }}>   <li>  Consultation </li></Link>
                                  
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-lg-3 col-md-6 col-sm-6 col-xs-12'>
                            <div>
                                <h5>LATEST NEWS</h5>

                                <ul className='footer-news'>
                                    <li>
                                        <img src={member1} alt="" />
                                        <div>
                                            <p>What is a “Healthy” Food?
                                                The Answer ...</p>
                                            <span>February 14, 2017</span>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={member2} alt="" />
                                        <div>
                                            <p>What is a “Healthy” Food?
                                                The Answer ...</p>
                                            <span>February 14, 2017</span>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={member3} alt="" />
                                        <div>
                                            <p>What is a “Healthy” Food?
                                                The Answer ...</p>
                                            <span>February 14, 2017</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12">
                            <div >
                                {/* <h5>NEWSLETTER</h5>
                                <NewsletterSubscription/> */}
                                <h5 className='my-4'>WORKING HOURS</h5>
                                <div className="working-hours">
                                    <ul>
                                        <li><p>Monday to Friday:</p><p>9.00 to 18.00</p></li>
                                        <li><p>Saturday:</p><p>9.00 to 18.00</p></li>
                                        <li><p>Sunday:</p><p style={{ color: "#2acb35" }}>Closed</p></li>
                                    </ul>
                                    {/* <div className='select-language' id="languageSelect"></div> */}
                                   {/* <ul> <li>  <p> Select Language:</p>  <select id="languageSelect"> </select></li></ul> */}
                                <ul hidden> <li>
                                <select id="languageSelect" class="dropdown-select">
                                    <option value="" disabled selected>Select a language</option>
                                </select></li></ul>                   
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <footer >
                <div className="container cmd">
                    <h6>    
                        Copyrights © 2024 All Rights Reserved, Crafted with precision by <span>Codfty.</span>
                    </h6>
                    <div>
                        <ul>
                            <li><a href="https://www.facebook.com/dr.siamalmobarakcardio/" target='blank'> <FacebookRoundedIcon /></a></li>
                            <li><a href="https://twitter.com/Siam_Mobarak" target='blank'><CloseIcon /></a></li>
                            <li><a href="https://www.linkedin.com/company/drsiamalmobarak_cardio/" target='blank'><LinkedInIcon /></a></li>
                            <li><a href="https://www.instagram.com/dr.siamalmobarakcardio/" target='blank'><InstagramIcon /></a></li>
                            <li><a href="https://www.pinterest.com/drsiamalmobarak_cardio" target='blank'><PinterestIcon /></a></li>
                            <li><a href="https://www.youtube.com/@dr.siamalmobarak_cardio" target='blank'><YouTubeIcon /></a></li>

                        </ul>
                    </div>
                </div>
            </footer>
        </>

    )
}

export default Footer